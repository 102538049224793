const FormError = (props: { error?: string }) => {
  const { error } = props;
  return !error ? null : (
    <p
      className="mt-2 text-sm text-white p-1 text-center bg-red-500 rounded-md"
      id="email-error"
    >
      {error}
    </p>
  );
};

export default FormError;
