import PageTitle from "../elements/PageTitle";
import SocialAuth from "../elements/SocialAuth";

const ModalSignIn = () => (
  <div className="space-y-6 w-full">
    <PageTitle title="Sign In" />
    <SocialAuth prefix="Sign in with" />
  </div>
);

export default ModalSignIn;
