import { useState } from "react";
import InputUsername from "../elements/InputUsername";
import PageTitle from "../elements/PageTitle";
import SocialAuth from "../elements/SocialAuth";

const ModalRegister = (props: { username?: string }) => {
  const { username } = props;
  const [currentUsername, setCurrentUsername] = useState(username);
  return (
    <div className="space-y-6 w-full">
      <PageTitle title="Sign Up To Claim" />
      <InputUsername
        value={currentUsername}
        handleChange={(e) => setCurrentUsername(e.target.value)}
      />
      <SocialAuth prefix="Sign up with" claim={currentUsername} />
    </div>
  );
};

export default ModalRegister;
