import { CSSProperties, ReactNode } from "react";
import { Disclosure } from "@headlessui/react";
import { LoginIcon, LogoutIcon, MenuIcon, XIcon } from "@heroicons/react/solid";
import {
  useAuthenticated,
  useOverlayModal,
  useOverlayNotif,
  useThunkDispatch,
} from "../../common/hooks";
import Logo from "./Logo";
import Button from "./Button";
import {
  overlayModal,
  overlayNotif,
  signout as signoutAction,
} from "../../redux/actions";
import OverlayNotif from "./OverlayNotif";
import OverlayModal from "./OverlayModal";
import ModalSignIn from "../modals/ModalSignin";
import { useRouter } from "next/router";
import Footer, { IFooterProps } from "./Footer";

const AuthenticatedHeader = () => {
  const dispatch = useThunkDispatch();
  const router = useRouter();
  const signout = async () => {
    await dispatch(signoutAction());
    router.replace("/", undefined, { shallow: true });
  };

  return (
    <Disclosure
      as="nav"
      className="fixed top-0 w-screen bg-gray-50 border-b border-gray-300"
    >
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Logo />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <Button onClick={signout} icon={LogoutIcon} label="Sign Out" />
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                  <span className="sr-only">Open Main Menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden border-b border-current">
            <div className="pb-4">
              <button
                onClick={signout}
                className="block px-4 py-2 font-light hover:underline focus:outline-none"
              >
                Sign Out
              </button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const UnauthenticatedHeader = (props: { showSignIn?: boolean }) => {
  const { showSignIn } = props;
  const dispatch = useThunkDispatch();
  return (
    <div className="fixed top-0 w-screen  bg-gray-50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between">
        <Logo />
        {showSignIn && (
          <Button
            onClick={() => {
              dispatch(overlayModal(<ModalSignIn />));
            }}
            icon={LoginIcon}
            label="Sign In"
          />
        )}
      </div>
    </div>
  );
};

const Page = (props: {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  showSignIn?: boolean;
  hideHeader?: boolean;
  showFooter?: boolean;
  footerProps?: IFooterProps;
}) => {
  const {
    children,
    className,
    style,
    showSignIn,
    hideHeader,
    showFooter,
    footerProps,
  } = props;

  const authenticated = useAuthenticated();
  const dispatch = useThunkDispatch();
  const currentOverlayNotif = useOverlayNotif();
  const currentOverlayModal = useOverlayModal();

  return (
    <>
      <main>
        <div
          style={{
            ...(style || {}),
            minHeight: "100vh",
            marginBottom: showFooter ? "6rem" : undefined,
          }}
          className={`${className || ""} ${
            hideHeader ? "" : "pt-8"
          } flex w-screen border-b border-gray-300`}
        >
          {children}
        </div>
      </main>
      <header className="text-gray-600">
        {!hideHeader &&
          (authenticated ? (
            <AuthenticatedHeader />
          ) : (
            <UnauthenticatedHeader showSignIn={showSignIn} />
          ))}
      </header>
      {showFooter && <Footer {...(footerProps || {})} />}
      <OverlayNotif
        show={!!currentOverlayNotif && currentOverlayNotif.show}
        body={!!currentOverlayNotif ? currentOverlayNotif.body : undefined}
        hide={() => {
          dispatch(overlayNotif());
        }}
      />
      <OverlayModal
        show={!!currentOverlayModal && currentOverlayModal.show}
        body={!!currentOverlayModal ? currentOverlayModal.body : undefined}
        hide={() => {
          dispatch(overlayModal());
        }}
      />
    </>
  );
};

export default Page;
