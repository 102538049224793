import React from "react";

export interface IButtonBaseProps {
  className?: string;
  icon?: any;
  label?: string;
  disabled?: boolean;
  onClick?;
  href?;
  submit?: boolean;
  flat?: boolean;
}

const ButtonBase = React.forwardRef(
  (props: IButtonBaseProps & { iconClassName?: string }, ref: any) => {
    const {
      className,
      iconClassName,
      icon,
      label,
      disabled,
      onClick,
      submit,
      flat,
    } = props;
    let Icon = icon;
    return (
      <button
        onClick={onClick}
        type={submit ? "submit" : "button"}
        ref={ref}
        disabled={disabled}
        className={`disabled:opacity-50 inline-flex items-center justify-center space-x-2 focus:outline-none ${
          !!className ? className : ""
        } ${disabled ? "pointer-events-none" : ""} ${flat ? "" : "rounded-md"}`}
      >
        {!!Icon && <Icon className={iconClassName} />}
        {!!label && <span>{label}</span>}
      </button>
    );
  }
);

export default ButtonBase;
