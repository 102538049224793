import Link from "next/link";

const Logo = () => (
  <Link href="/" shallow>
    <img
      className="w-36 sm:w-44 h-full cursor-pointer"
      src="/paymewith/default.svg"
      alt="PayMeWith"
    />
  </Link>
);

export default Logo;
