import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { useThunkDispatch } from "../../../common/hooks";
import { overlayModal } from "../../../redux/actions";
import InputUsername from "../../elements/InputUsername";
import Page from "../../elements/Page";
import PageHead from "../../elements/PageHead";
import ModalRegister from "../../modals/ModalRegister";

const Logo = (props: { title: string; image: string }) => (
  <div className="col-span-1 flex justify-center">
    <Image src={props.image} alt={props.title} width="112" height="44" />
  </div>
);

const Logos = () => (
  <div className="max-w-4xl m-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 gap-y-8">
    <Logo title="Venmo" image="/logos/venmo.svg" />
    <Logo title="Cash App" image="/logos/cashapp.svg" />
    <Logo title="PayPal" image="/logos/paypal.svg" />
    <Logo title="Zelle" image="/logos/zelle.svg" />
    <Logo title="Google Pay" image="/logos/googlepay.svg" />
    <Logo title="Apple Pay" image="/logos/applepay.svg" />
  </div>
);

const Title = () => (
  <h1 className="text-4xl tracking-tight text-gray-600 font-extrabold sm:text-5xl md:text-6xl text-center">
    <span className="block xl:inline">One page for all your</span>{" "}
    <span className="block text-secondary xl:inline">social payments.</span>
  </h1>
);

const Claim = () => {
  const dispatch = useThunkDispatch();
  const [username, setUsername] = useState<string>();
  return (
    <div className="mt-12 ">
      <div className="sm:max-w-lg sm:mx-auto sm:text-center sm:flex">
        <InputUsername
          value={username}
          handleChange={(e) => setUsername(e.target.value)}
        />
        <button
          type="button"
          onClick={() => {
            dispatch(overlayModal(<ModalRegister username={username} />));
          }}
          className="mt-3 w-full px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary shadow-sm hover:bg-primary-600 focus:outline-none sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
        >
          Claim
        </button>
      </div>
      <div className="mt-8 text-center">
        <span className="text-sm text-gray-500">
          Looking for an example?
          <br className="visible sm:hidden" /> Check out
          <Link href="/@hasham">
            <a className="ml-1 text-secondary hover:text-secondary-600 font-bold">
              @hasham
            </a>
          </Link>
        </span>
      </div>
    </div>
  );
};

const Landing = () => (
  <Page showSignIn showFooter footerProps={{ hideMadeBy: true }}>
    <PageHead />
    <div className="m-auto max-w-7xl py-24 sm:py-0 px-4 space-y-16">
      <Title />
      <Claim />
      <Logos />
    </div>
  </Page>
);

export default Landing;
