import React from "react";
import ButtonBase, { IButtonBaseProps } from "./ButtonBase";

const Button = React.forwardRef((props: IButtonBaseProps, ref: any) => {
  const { className } = props;
  return (
    <ButtonBase
      ref={ref}
      {...props}
      className={`text-white px-4 py-2 font-medium ${
        !!className ? className : "bg-primary hover:bg-primary-600"
      }`}
      iconClassName="w-5 h-5"
    />
  );
});

export default Button;
