import { useRouter } from "next/router";
import { useAuthenticated } from "../../common/hooks";
import Landing from "./public/Landing";
import Loading from "./public/Loading";

const Home = () => {
  const authenticated = useAuthenticated();
  const router = useRouter();

  if (authenticated) {
    router.push("/manage/profile");
  }

  return authenticated ? <Loading /> : <Landing />;
};

export default Home;
