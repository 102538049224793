import React, { useCallback, useEffect, useState } from "react";
import { get } from "../../common/gateway";
import { useAuthenticated } from "../../common/hooks";
import FormError from "./FormError";
import debounce from "lodash.debounce";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import LoadingIcon from "../icons/LoadingIcon";

const InputUsername = (props: {
  originalValue?: string;
  small?: boolean;
  value?: string;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  handleBlur?: (e: React.FocusEvent<any>) => void;
  error?: string;
}) => {
  const {
    originalValue,
    small,
    value,
    handleChange,
    handleBlur,
    error,
  } = props;

  const authenticated = useAuthenticated();
  const [claimed, setClaimed] = useState(false);
  const [checkingClaimed, setCheckingClaimed] = useState(false);

  const doClaimCheck = useCallback(
    debounce(async () => {
      if (authenticated) {
        if (!value || !!error) {
          setClaimed(true);
          return;
        }

        if (value === originalValue) {
          setClaimed(false);
          return;
        }

        setCheckingClaimed(true);
        const response = await get(`users/${value}/claimed`, undefined, true);

        setCheckingClaimed(false);

        if (!!response) {
          setClaimed(response.claimed);
          return;
        }
      }

      setClaimed(false);
    }, 250),
    [authenticated, value, error, setClaimed, setCheckingClaimed]
  );

  useEffect(() => {
    doClaimCheck();
  }, [value, error]);

  return (
    <div>
      <div className="flex w-full">
        <label htmlFor="username" className="sr-only">
          Username
        </label>
        <span
          className={`${
            small ? "text-sm py-2" : "py-4"
          } inline-flex items-center px-3 rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none border border-gray-200 bg-gray-200 text-gray-600 font-bold`}
        >
          {process.env.NEXT_PUBLIC_DOMAIN}/@
        </span>
        <input
          type="text"
          name="username"
          id="username"
          className={`${
            small ? "text-sm" : ""
          } rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md appearance-none relative block w-full px-3 border border-gray-300 placeholder-gray-400 text-gray-600 focus:outline-none`}
          placeholder="yourname"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {authenticated && (
          <div className="bg-white p-2 rounded-tr-md rounded-br-md">
            {checkingClaimed && (
              <LoadingIcon className="text-primary w-5 h-5" />
            )}
            {!checkingClaimed && claimed && (
              <XCircleIcon className="text-red-500 w-6 h-6" />
            )}
            {!checkingClaimed && !claimed && (
              <CheckCircleIcon className="text-green-500 w-6 h-6" />
            )}
          </div>
        )}
      </div>
      <FormError error={error} />
    </div>
  );
};

export default InputUsername;
