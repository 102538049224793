import { ExclamationCircleIcon } from "@heroicons/react/solid";

const NotifError = (props: { text?: string }) => (
  <div className="text-red-500 space-x-4 inline-flex items-center">
    <ExclamationCircleIcon className="w-5 h-5" />
    <span className="text-sm">
      {props.text || "Something went wrong, please try again."}
    </span>
  </div>
);

export default NotifError;
