import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useThunkDispatch } from "../../common/hooks";
import {
  overlayModal,
  overlayNotif,
  signinWithFacebook,
  signinWithGoogle,
  signinWithTwitter,
} from "../../redux/actions";
import NotifError from "../notifs/NotifError";

const ProviderButton = (props: {
  className?: string;
  title: string;
  icon: ReactNode;
  onClick: () => void;
}) => {
  const { className, title, icon, onClick } = props;

  return (
    <div>
      <button
        type="button"
        onClick={onClick}
        className={`w-full inline-flex justify-center py-4 px-4 space-x-4 rounded-md text-sm sm:text-base font-medium text-white focus:outline-none ${className}`}
      >
        {icon}
        <span>{title}</span>
      </button>
    </div>
  );
};

const SocialAuth = (props: { prefix: string; claim?: string }) => {
  const { prefix, claim } = props;
  const dispatch = useThunkDispatch();
  const router = useRouter();

  const route = () => {
    if (!!claim) {
      router.push(
        `/manage/profile/edit?claim=${claim}`,
        "/manage/profile/edit",
        { shallow: true }
      );
    } else {
      router.push("/", undefined, { shallow: true });
    }
  };

  return (
    <div className="space-y-4">
      <ProviderButton
        className="bg-blue-600 hover:bg-blue-700"
        title={`${prefix} Facebook`}
        icon={
          <svg
            className="w-5 h-5 sm:w-6 sm:h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
              clipRule="evenodd"
            />
          </svg>
        }
        onClick={async () => {
          const result = await dispatch(signinWithFacebook());
          if (result) {
            route();
          } else {
            dispatch(overlayModal(undefined));
            dispatch(
              overlayNotif(
                <NotifError text="Unable to sign in with Facebook." />
              )
            );
          }
        }}
      />

      <ProviderButton
        className="bg-blue-400 hover:bg-blue-500"
        title={`${prefix} Twitter`}
        icon={
          <svg
            className="w-5 h-5 sm:w-6 sm:h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        }
        onClick={async () => {
          const result = await dispatch(signinWithTwitter());
          if (result) {
            route();
          } else {
            dispatch(overlayModal(undefined));
            dispatch(
              overlayNotif(
                <NotifError text="Unable to sign in with Twitter." />
              )
            );
          }
        }}
      />

      <ProviderButton
        className="bg-red-500 hover:bg-red-600"
        title={`${prefix} Google`}
        icon={
          <div className="w-5 h-5 sm:w-6 sm:h-6">
            <Image src="/google.svg" width="100%" height="100%" alt="Google" />
          </div>
        }
        onClick={async () => {
          const result = await dispatch(signinWithGoogle());
          if (result) {
            route();
          } else {
            dispatch(overlayModal(undefined));
            dispatch(
              overlayNotif(<NotifError text="Unable to sign in with Google." />)
            );
          }
        }}
      />

      <div>
        <span className="text-xs sm:text-sm text-gray-400">
          By signing in, you agree to our
          <Link href="/privacy" shallow>
            <a className="mx-1 text-secondary hover:underline" target="_blank">
              Privacy Policy
            </a>
          </Link>
          and
          <Link href="/terms" shallow>
            <a className="mx-1 text-secondary hover:underline" target="_blank">
              Terms of Service
            </a>
          </Link>
          .
        </span>
      </div>
    </div>
  );
};

export default SocialAuth;
