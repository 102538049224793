import Link from "next/link";
import Logo from "./Logo";

export interface IFooterProps {
  showLogo?: boolean;
  hideMadeBy?: boolean;
}

const Footer = (props: IFooterProps) => {
  const { showLogo, hideMadeBy } = props;
  return (
    <footer
      style={{ zIndex: -1 }}
      className="fixed bottom-0 right-0 left-0 my-4 mx-4 sm:mx-8"
    >
      <div className="flex items-center justify-between">
        <div>
          {showLogo && <Logo />}
          {!hideMadeBy && (
            <div>
              <span className="text-gray-400 text-sm text-right">Made By</span>
              <Link href="/@hasham">
                <a className="ml-1 text-secondary hover:text-secondary-600 font-bold">
                  @hasham
                </a>
              </Link>
            </div>
          )}
        </div>
        <div className="text-right">
          <div>
            <Link href="/privacy" shallow>
              <a className="hover:underline font-light inline-flex items-center px-1 pt-1 text-sm xs:text-normal">
                Privacy Policy
              </a>
            </Link>
          </div>
          <div>
            <Link href="/terms" shallow>
              <a className="hover:underline font-light inline-flex items-center px-1 pt-1 text-sm xs:text-normal">
                Terms of Service
              </a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
